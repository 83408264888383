import React, {createContext, useState} from 'react'

const VoicemateDataContext = createContext();


function VoicemateDataProvider(props) {
const [language, setLanguage] = useState('en');

React.useEffect(()=> {
console.log('alerts', language)
}, [language])

    return (
       
        <VoicemateDataContext.Provider value={{language, setLanguage}}>
            {props.children}
        </VoicemateDataContext.Provider>
        
    )
}
export {VoicemateDataProvider, VoicemateDataContext}
export default VoicemateDataProvider;
