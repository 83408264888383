
import React from 'react';
import Contact from './Contact'


const Home = (props) => {

  const { t, i18n } = props

    return (
        <section>
       <div className="bg_red">
          <div className="container" id="home">
          <div className="heading pt-2 pb-2">
              <h4 className="vm">{t('banner_title')}</h4>
              <p className="mt-3 mb-2">{t('banner_subtitle')}</p>
          </div>
         </div>
      </div>
      <div className="none deskframe">
          <div className="container">
           <div className="frame">
        		<div className="col-12 col-sm-10 offset-sm-1">
              <div className="desktop text-center">
                <img src="img/img.gif" className="img-fluid" alt="..." />
              </div>
              </div>
         
          </div>
          </div>
      </div>

      <div className="container mt-xl-4 mt-0" id="challenges">
          <div className="heading-2">
              <div className="bc" >
                  <h4 className="color-red">{t('main_title')} <br /> <span className="titlemark grey">{t('main_title2')}</span></h4>
              </div>
          </div>
          <div className="row">
              <div className="col">
                <img src={t('image1')} className="img-fluid" alt="..." />
              </div>
          </div>
          <div className="row">
            <div className="col">
              <img src={t('image2')} className="img-fluid" alt="..." />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <img src={t('image3')} className="img-fluid" alt="..." />
            </div>
          </div>
      </div>

      <div className="bg_red_1 pb-4" id="about">
      <div className="container-fluid">	
          <div className="heading-3 pt-4">
              <h4 className="mb-2">{t ('what_title')}</h4>
              <p> <span>{t('span1')}</span> <strong>{t('strong1')}</strong> <span>{t('span2')}</span> 
                <strong>{t('strong2')}<br />{t('strong3')}</strong> 
              <span>{t('span3')}</span>  <br /> <span>{t('span4')}</span>  <strong>{t('strong4')}<br />{t('strong5')}</strong></p>
              
          </div>
          <div className="row">
              <div className="col-md-3 col-lg-3 col-sm-12 col-12 m-0 p-0">
                <img src="img/hand.png" className="img-fluid" alt="..." />
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                <img src="img/vme.gif" className="img-fluid" alt="..." />
              </div>
              <div className="col-md-3 col-lg-3 col-sm-12 col-12 p-0 m-0">
                <img src="img/hand2.png" className="img-fluid" alt="..." />
              </div>
          </div>
          <div className="row">
          
              <div className="how-to text-center mt-lg-0 mt-5 d-flex align-items-center justify-content-center">
              <video playsinline="playsinline" muted="muted" preload="yes" autoplay="autoplay" loop="loop" controls="controls" id="vjs_video_520_html5_api" class="video-js" data-setup='{"autoplay":"any"}'>
    		<source src="./video/file.mp4" type="video/mp4" />
      </video>
            
              
              </div>
          </div>
          </div> 
      </div>

      <div className="container mt-4 pb-lg-5 pb-3" id="clients">
          <div className="heading-4" >
              <h4 className="color-red">{t ('client_title')} <br /><span className="titlemark">{t ('client_title2')}</span></h4>
          </div>
          <div className="row text-center mt-lg-5 mt-0 align-items-center">
            <div className="col-md-4 col-lg-4 col-sm-4 col-4">
              <img src="img/hz.webp" className="img-fluid"  alt="..." width="239" height="80" />
            </div>
            <div className="col-md-4 col-lg-4 col-sm-4 col-4">
              <img src="img/ib.webp" className="img-fluid" alt="..." width="264" height="80" />
            </div>
            <div className="col-md-4 col-lg-4 col-sm-4 col-4">
              <img src="img/rp.webp" className="img-fluid" alt="..." width="173" height="80" />
            </div>
        </div>
      </div>
      <div className="map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39511.22237342363!2d106.67835313716591!3d10.73373332124144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752fad6ba614e5%3A0xd3a86ff892151fc3!2zc-G7kSA4LCAyMTggxJDGsOG7nW5nIENhbyBM4buXLCBwaMaw4budbmcgNCwgUXXhuq1uIDgsIFRow6BuaCBwaOG7kSBI4buTIENow60gTWluaCwgVmlldG5hbQ!5e0!3m2!1sen!2sin!4v1625460840559!5m2!1sen!2sin" width="100%" height="400" loading="lazy"></iframe>
      </div>


      <div className="mt-4 pb-5 contact" id="contact">
      <div className="container">
        <div className="heading-5">
            <h4 className="color">{t('demo_title')}</h4>
            <h5  className="color">{t('contact_title')}</h5>
        </div>
        <div className="row" style={{justifyContent: 'center'}}>
            <Contact t={t} i18n={i18n} />
      </div>
      </div>
    </div>


     

      
   
         

    </section>  
        
    )

};

export default Home;


   

    

    

    