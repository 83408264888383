
import React from 'react';
import Header from './pages/Header';
import Home from './pages/Home';
import Footer from './pages/Footer';
import i18n from "./i18next";
import { useTranslation } from "react-i18next";
import {VoicemateDataProvider} from './context/DataContext';




function App() {
  const { t } = useTranslation()
  return (
    <>
     <VoicemateDataProvider>
    <Header  t={t} i18n={i18n} />
      <Home t={t} i18n={i18n}  />
   <Footer t={t} i18n={i18n}  />
   </VoicemateDataProvider>
   </>
  );
}

export default App;
