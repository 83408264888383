import React, {useState, useContext} from 'react'

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css'; 
import {VoicemateDataContext} from '../context/DataContext';




const Contact = (props) => {
  const [msg, setMsg] = useState (null);
  const [errmsg, setErrMsg] = useState (null);
  const voicemateAction = useContext(VoicemateDataContext);
  const language = voicemateAction?.language
     
    const { t } = (props) 

  
  const [loading, setLoading] = useState(false);
  


  const url = "https://contactforms.thevoicemate.com/contact-us/ "
 


  const [userRegistration, setUserRegistration] = useState({
      name: "",
      email: "",
      phone: "",
      subject: "",
      address: "",
      language: "",
      identifier: "Voicemate Enabler"
  });


  const handleInput = (e) => {

      const name = e.target.name;
      
      const value = e.target.value;
     
      setUserRegistration({ ...userRegistration, [name]: value })
             

    
      
  }

  const [records, setRecords] = useState([]);

  const handleSubmit =  (e) => {
    e.preventDefault();
  

      const newRecord = { ...userRegistration}
      setRecords([ ...records, newRecord]);


     
      setLoading(true);
      
       axios.post(url, {
      name: userRegistration.name,
      email: userRegistration.email,
      phone: userRegistration.phone,
      subject: userRegistration.subject,
      address: userRegistration.address,
      language,
      identifier: userRegistration.identifier
      })
      
     .then(function (response) {
      setLoading(false);
      if(response.status === 200){
        console.log(response);
        setMsg(response.data.msg)
        setLoading(false);  
      setUserRegistration({name:"", email:"", phone: "", language: "",  identifier: "Voicemate Enabler", subject: "", address: ""})

              

      }
     
      
  })
      .catch(function (error) {
          setLoading(false);
          console.log(error.response);
          console.log(error.request);
          console.log(error);
          setErrMsg(error.response.data.msg)      
   
       });
            

        
  }


    return (
      <>
      <div className="contacts">
        <div className="col-md-6 col-lg-6 col-sm-12 col-12 ">
        
            <form onSubmit={(e)=>handleSubmit(e)}>
            <div className="row">
                <div className="mb-4 col-lg-6">
                <label className="fl-left">{t ('contact_name_pls')} <span className="red">*</span></label>
                <input type="text" value={userRegistration.name} onChange={handleInput} 
                       className="form-control" name="name"  placeholder={t ('contact_name_pls')}  required />
                  </div>

                  <div className="mb-4 col-lg-6">
                <label className="fl-left">{t ('contact_email_pls')} <span className="red">*</span></label>

                <input type="email" value={userRegistration.email} onChange={handleInput} 
                       className="form-control" name="email"  placeholder={t ('contact_email_pls')}  required />
                  </div>

                   <div className="mb-4 col-lg-6">
                <label className="fl-left">{t ('contact_phone_pls')} <span className="red">*</span></label>

                <input type="number" value={userRegistration.phone} onChange={handleInput} 
                       className="form-control" name="phone"  placeholder={t ('contact_phone_pls')}  required />
                  </div>

                  <div className="mb-4 col-lg-6">
                <label className="fl-left">{t ('contact_subject_pls')} <span className="red">*</span></label>

                <input type="text" value={userRegistration.subject} onChange={handleInput} 
                       className="form-control" name="subject"  placeholder={t ('contact_subject_pls')}  required />
                  </div>


                   <div className="mb-4 col-lg-12">
                <label className="fl-left">{t ('contact_Address_pls')}</label>

                <input type="text" value={userRegistration.address} onChange={handleInput} 
                       className="form-control" name="address"  placeholder={t ('contact_Address_pls')}   />
                  </div>

              </div> 


                  { msg &&
                  <div class="alert alert-success">
                  {msg}
                  </div>
                  } 

               

                      { errmsg &&
                        <div class="alert alert-danger">
                  {errmsg}
                  </div>

                      } 
                  

                  <div className="mb-4">
                  <input type="hidden" className="form-control" 
                      name="identifier"
                      value={userRegistration.identifier} onChange={handleInput} 
                       placeholder="identifier"  />
                  </div>
                
                <Button type="submit" loading={loading}
                  appearance="primary btns">{t ('contact_submit')}</Button>
                   <ToastContainer />
            
              </form>  
              </div> 
              <div className="col-lg-6">

              </div>
              </div>
              </>
    )
}

export default Contact
