

import React from 'react';


const Footer = (props) => {
    
const { t } = (props) ;

 return (
<section>

<div className="chat">
   <a  href="#"> <img src="img/chat.png" alt="" /></a>
</div>


    <div className="container-fluid bg_red_1 pt-5 ">
        <div className="container">
            <div className="row">
                  <div className="col-lg-4 col-6 links">
                    <h5>QUICK LINKS</h5>
                    <ul>
                        <li>{t('head1')}</li>
                        <li>{t('head2')}</li>
                        <li>{t('head3')}</li>
                        <li>{t('head4')}</li>
                        <li>{t('head5')}</li>
                    </ul>
                  </div>
                  <div className="col-lg-4 col-6 links get_in">
                      <h5>{t ('get_touch')}</h5>
                      <a href="mailto:info@thevoicemate.com">info@thevoicemate.com</a><br />
                      <a href="tel:+84939469074">Tel: +84 93 946 90 74</a> <br />
                      <a href="https://thevoicemate.com/">https://thevoicemate.com</a>
                  </div>
                  <div className="col-lg-4 col-12 links social">
                    <h5>SOCIAL LINKS</h5>
                    <ul>
                        <li className="me-3">
                            <a target="_blank" href="https://www.linkedin.com/company/voicemate/"><i className="fa fa-linkedin"></i></a>
                        </li>
                        <li className="me-3">
                            <a target="_blank" href="https://www.facebook.com/thevoicemate"><i className="fa fa-facebook"></i></a>
                        </li>
                        <li className="me-3">
                            <a target="_blank" href="https://www.instagram.com/voice_mate/"><i className="fa fa-instagram"></i></a>
                        </li>
                    </ul>
                  </div>

          <div className="col-12 mt-5 border-top pt-2 pb-2">
        <div className="credit">
        <p style={{textAlign: 'center'}}>Copyright © 2021 VOICEMATE All Rights Reserved</p>
        <p>VOICEMATE Technology Company Limited <br /> Certificate Number: 0316715597 <br />
Issued by Department of Planning And Investment Of Ho Chi Minh on 17/02/2021</p>
            <img src="https://thevoicemate.com/static/media/logoSaleNoti.f3d3b02c.png" alt="" />
        </div>
    </div>
            </div>
        </div>
    </div>

</section>
         




    )

};

export default Footer;





