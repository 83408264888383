

import React, {useState, useContext} from 'react';
import { Link } from 'react-router-dom'
import { Dropdown } from "rsuite"
import 'rsuite/dist/styles/rsuite-default.css'; 
import {VoicemateDataContext} from '../context/DataContext';





const Header = (props) => {
  const voicemateAction = useContext(VoicemateDataContext);

  const [isClicked, setClicked] = useState(true)

  const { t, i18n } = props

  const changelanguage = (ln) => {
      return () => {
          i18n.changeLanguage(ln);
          console.log("I am change language", ln)
      }
  }
  function changeContextLanguage(lang) {
    // changelanguage(lang)
    i18n.changeLanguage(lang);
    voicemateAction.setLanguage(lang)
}

    return(

      <>

      <div className="navigations">
            <div className="col-lg-12">
            <nav class="navbar navbar-expand-lg navbar-light">
  <div class="container align-items-lg-end align-items-center">
        <Link className="navbar-brand" to="/">
         <h5 className="powered">Powered by:</h5>
            <img src="img/vmlogo.webp" alt="" width="185" />
        </Link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
      <ul class="navbar-nav align-items-center">
      <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="#home">{t('head1')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link " aria-current="page" href="#challenges">{t('head2')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link " aria-current="page" href="#about">{t('head3')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link " aria-current="page" href="#clients">{t('head4')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link " aria-current="page" href="#contact">{t('head5')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link " aria-current="page" href="#contact">Demo</a>
            </li>
        {isClicked && <Dropdown className="dropit" title={t('language')} >
                        <Dropdown.Item onClick={() => changeContextLanguage("en")} >English</Dropdown.Item>
                        <Dropdown.Item onClick={() => changeContextLanguage("vi")} >Vietnamese</Dropdown.Item>
                    </Dropdown>}
      </ul>
    </div>
  </div>
</nav>
            </div>
          </div>
        
      

    

      </>
   

    )
};

export default Header;




